import TC from "../../../components/table/Table.module.css";

import type { BillingAccountInvoice, InvoiceId } from "@warrenio/api-spec/spec.oats.gen";
import { InvoiceStatusEnum } from "@warrenio/api-spec/spec/billingEnums";
import { filterFalse } from "@warrenio/utils/collections/filterFalse";
import { getAndAssert } from "@warrenio/utils/collections/getAndAssert";
import { notNull } from "@warrenio/utils/notNull";
import type { ReactNode } from "react";
import { ContentPane } from "../../../components/ContentPane.tsx";
import { Separator } from "../../../components/Separator.tsx";
import { Spacer } from "../../../components/Spacer.tsx";
import { ViewTitle } from "../../../components/Title.tsx";
import { Toolbar } from "../../../components/Toolbar.tsx";
import { VatText } from "../../../components/VatText.tsx";
import { WButton } from "../../../components/button/WButton.tsx";
import { WModalButton } from "../../../components/button/WToolButton.tsx";
import { CurrencyBalance, CurrencyHourly } from "../../../components/l10n/Currency.tsx";
import { WModal, WModalContent } from "../../../components/modal/WModal.tsx";
import { DetailsHolder, DetailsTable } from "../../../components/table/DetailsTable.tsx";
import { DetailsTableRow } from "../../../components/table/DetailsTableRow.tsx";
import { WTable, WTableBody } from "../../../components/table/WTable.tsx";
import { downloadBlob } from "../../../utils/downloadBlob.tsx";
import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { useApiClient } from "../../api/apiClient.store.ts";
import { getResourceById } from "../../api/resourceTypeException.ts";
import { useStandardMutation } from "../../api/useStandardMutation.ts";
import { useThemeProps } from "../../theme/useTheme.ts";
import { ReportStatus } from "../ReportStatus.tsx";
import { rawBillingAccountQueryAtom, type BillingAccountWithType } from "../billingAccountQuery.ts";
import { baInvoiceQueryAtom, payInvoiceMutation } from "../billingInvoicesQuery.ts";
import { baUsageQueryAtom } from "../billingUsageQuery.ts";
import { billingAccountLink } from "../links.ts";
import { PrimaryCardShortInfo } from "../paymentMethodLogic.tsx";
import { InvoiceDueDate, InvoiceIssueDate, InvoicePeriod } from "./InvoiceDates.tsx";
import { createCurrentUsage } from "./invoiceLogic.tsx";

export function InvoiceView({ baId, invoiceId }: { baId: number; invoiceId: InvoiceId }) {
    //#region Hooks
    const invoicesData = useSuspenseQueryAtom(baInvoiceQueryAtom(baId));
    const usageData = useSuspenseQueryAtom(baUsageQueryAtom(baId));
    const data = useSuspenseQueryAtom(rawBillingAccountQueryAtom);
    //#endregion

    const billingAccount = getResourceById(data, baId, "billing_account");
    const invoice =
        invoiceId === "current"
            ? createCurrentUsage([...usageData.values()], billingAccount)
            : getAndAssert(invoicesData, invoiceId, "Invoice");

    return (
        <>
            <InvoiceToolbar invoice={invoice} billingAccount={billingAccount} />
            <InvoiceDetails invoice={invoice} billingAccount={billingAccount} />
            <Separator />
            <ServicesContent invoice={invoice} billingAccount={billingAccount} />
        </>
    );
}

function getIsReport({ type }: BillingAccountInvoice, { allow_debt }: BillingAccountWithType) {
    return !allow_debt && type === "cloud_services";
}

interface InvoiceProps {
    invoice: BillingAccountInvoice;
    billingAccount: BillingAccountWithType;
}

function InvoiceToolbar({ invoice, billingAccount }: InvoiceProps) {
    const api = useApiClient();

    const onPayInvoiceMutation = useStandardMutation(payInvoiceMutation);
    const { invoiceAltTranslations } = useThemeProps();

    const { padded_id, status, id } = invoice;
    const { paying_by_invoice, primary_card } = billingAccount;
    const isReport = getIsReport(invoice, billingAccount);

    async function onPayInvoice(invoiceId: number) {
        await onPayInvoiceMutation.mutateAsync({
            invoice_id: invoiceId,
        });
    }

    function getPdfName() {
        const invoicePrefix = invoiceAltTranslations?.pdfFilePrefix ?? "Invoice";
        const prefix = isReport ? "Usage Report" : invoicePrefix;
        return `${prefix} ${padded_id}.pdf`;
    }

    async function downloadPdf() {
        const response = await api.GET("/payment/invoice/pdf", {
            params: { query: { invoice_id: id } },
            parseAs: "blob",
        });

        downloadBlob(notNull(response.data, "PDF data"), getPdfName());
    }

    const canPay = status === InvoiceStatusEnum.UNPAID && primary_card != null && !paying_by_invoice && !isReport;

    return (
        <Toolbar>
            <WButton
                color="muted"
                size="bar"
                variant="ghost"
                action={billingAccountLink(billingAccount)}
                icon="jp-icon-caretleft"
            >
                Billing Account
            </WButton>

            {!isReport && !!primary_card && (
                <WModal
                    button={
                        <WModalButton
                            label="Pay Now"
                            icon="jp-card-icon"
                            isDisabled={!canPay || onPayInvoiceMutation.isPending}
                        />
                    }
                >
                    <WModalContent title="Pay Invoice" label="Pay Now" modalAction={() => onPayInvoice(Number(id))}>
                        By paying the invoice #{padded_id} the total amount will be taken from your credit card
                        <PrimaryCardShortInfo card={primary_card} />.
                    </WModalContent>
                </WModal>
            )}

            {!!padded_id && (
                <WButton color="primary" size="bar" variant="ghost" action={downloadPdf} icon="jp-icon-download">
                    Download PDF
                </WButton>
            )}
        </Toolbar>
    );
}

function InvoiceDetails({ invoice, billingAccount }: InvoiceProps) {
    const { status, padded_id, name, type } = invoice;

    const { title, allow_debt } = billingAccount;
    const isReport = getIsReport(invoice, billingAccount);

    return (
        <ContentPane className="VStack gap-4">
            <ViewTitle
                title={name ? name : `Report #${padded_id}`}
                // TODO: Remove global margins from all headings and instead use container-based layouts (eg. gap)
                // (otherwise it's impossible to lay out optional (nullable) components nicely without passing additional margin properties into every one of them)
                className="important:pb-0 important:min-h-auto"
            />

            <DetailsHolder>
                <DetailsTable>
                    <DetailsTableRow title="Report ID:">{padded_id ? `#${padded_id}` : name}</DetailsTableRow>

                    {(allow_debt || type === "credit_topup") && (
                        <DetailsTableRow title="Issue date:">
                            {name && type !== "credit_topup" ? "-" : <InvoiceIssueDate invoice={invoice} />}
                        </DetailsTableRow>
                    )}

                    {(allow_debt || type === "credit_topup") && (
                        <DetailsTableRow title="Due date:">
                            {name && type !== "credit_topup" ? "-" : <InvoiceDueDate invoice={invoice} />}
                        </DetailsTableRow>
                    )}

                    {padded_id && type !== "credit_topup" && (
                        <DetailsTableRow title="Period:">
                            <InvoicePeriod invoice={invoice} />
                        </DetailsTableRow>
                    )}

                    {!isReport && (
                        <DetailsTableRow title="Status:">
                            <ReportStatus value={status} />
                        </DetailsTableRow>
                    )}

                    <DetailsTableRow title="Billing account:">{title}</DetailsTableRow>
                </DetailsTable>

                <Spacer />

                <DetailsTable>
                    {getSummaryFields(invoice, billingAccount).map(({ title, value }, index) => (
                        <DetailsTableRow key={index} title={title}>
                            {value}
                        </DetailsTableRow>
                    ))}
                </DetailsTable>
            </DetailsHolder>
        </ContentPane>
    );
}

interface SummaryField {
    title: ReactNode;
    value: ReactNode;
}

function getSummaryFields(invoice: BillingAccountInvoice, billingAccount: BillingAccountWithType): SummaryField[] {
    const { totals, discount_percentage, vat_percentage } = invoice;

    const subtotal: SummaryField = { title: "Subtotal", value: <CurrencyBalance value={totals.subtotal} /> };

    const isReport = getIsReport(invoice, billingAccount);
    if (isReport) {
        return [subtotal];
    }

    return filterFalse<SummaryField>([
        subtotal,
        !!discount_percentage && {
            title: `Discount ${discount_percentage}%`,
            value: <CurrencyBalance value={totals.discount_amount} />,
        },
        !!totals.credit && {
            title: "Credit",
            value: <CurrencyBalance value={totals.credit} />,
        },
        {
            title: <VatText prefix="Total excl." />,
            value: <CurrencyBalance value={totals.total_before_tax} />,
        },
        {
            title: <VatText suffix={`(${vat_percentage}%)`} />,
            value: <CurrencyBalance value={totals.vat_tax} />,
        },
        {
            title: "Total",
            value: <CurrencyBalance value={totals.total_before_tax + totals.vat_tax} />,
        },
    ]);
}

export function ServicesContent({ invoice, billingAccount }: InvoiceProps) {
    const { records_list } = invoice;
    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Services</h2>

            <WTable>
                <thead>
                    <tr>
                        <th>Service</th>
                        <th>Qty</th>
                        <th className="text-right">Unit</th>
                        <th className="text-right">Amount</th>
                    </tr>
                </thead>
                <WTableBody>
                    {records_list.map((item) => {
                        const { id, item_price, name, qty, qty_unit, amount } = item;

                        return (
                            <tr key={id}>
                                <td>{name}</td>
                                <td>
                                    {qty} {qty_unit}
                                </td>
                                <td className="text-right">
                                    <CurrencyHourly value={item_price} /> / {qty_unit}
                                </td>
                                <td className="text-right">
                                    <CurrencyBalance value={amount} />
                                </td>
                            </tr>
                        );
                    })}
                </WTableBody>
                <tfoot className={TC.Clean}>
                    {getSummaryFields(invoice, billingAccount).map(({ title, value }, index) => (
                        <tr key={index}>
                            <td />
                            <td />
                            <td className="text-right">{title}</td>
                            <td className="text-right">{value}</td>
                        </tr>
                    ))}
                </tfoot>
            </WTable>
        </ContentPane>
    );
}
